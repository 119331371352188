import WebGLView from "./webgl/WebGLView";
import GUIView from "./gui/GUIView";
import "./../sass/style.scss";
export default class App {
  constructor() {}

  init() {
    this.initWebGL();
    this.initGUI();
    this.addListeners();
    this.animate();
    this.resize();
    this.getWebsiteInfo();
  }

  getWebsiteInfo() {
    const apiKey =
      "d5ca23bf47c90b37493e1cdd80ad1cf3ef0d9a06a90432155ca914af0e6d7fed9263b007fdee3b706f3c9e81665b75be1675ce4701fb7af4196856dbb6382189c2d5e53f3d16b2570fe2666922ebe05d7c7a521e8f9fd9421e74bb4308128d74ffb13d0d1a876731cbebb0c3989ee35a49c4d5af6a89cc95a3b0f0c22033937c";
    const apiUrl = "https://backend.k1sina.com/api/website-info";

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        var elements = document.getElementsByClassName("firstName");
        for (var i = 0; i < elements.length; i++) {
          elements[i].innerHTML = data.data.attributes.firstName;
        }
        var elements = document.getElementsByClassName("lastName");
        for (var i = 0; i < elements.length; i++) {
          elements[i].innerHTML = data.data.attributes.lastName;
        }
        var elements = document.getElementsByClassName("email");
        for (var i = 0; i < elements.length; i++) {
          elements[i].innerHTML = data.data.attributes.email;
        }
        var elements = document.getElementsByClassName("phone");
        for (var i = 0; i < elements.length; i++) {
          elements[i].innerHTML = data.data.attributes.phone;
        }
        var elements = document.getElementsByClassName("about");
        for (var i = 0; i < elements.length; i++) {
          elements[i].innerHTML = data.data.attributes.about;
        }
        var elements = document.getElementsByClassName("copyright");
        for (var i = 0; i < elements.length; i++) {
          elements[i].innerHTML = data.data.attributes.copyright;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  initWebGL() {
    this.webgl = new WebGLView(this);
    if (document.querySelector(".face"))
      document
        .querySelector(".face")
        .appendChild(this.webgl.renderer.domElement);
  }

  initGUI() {
    this.gui = new GUIView(this);
  }

  addListeners() {
    this.handlerAnimate = this.animate.bind(this);

    window.addEventListener("resize", this.resize.bind(this));
    window.addEventListener("keyup", this.keyup.bind(this));

    const el = this.webgl.renderer.domElement;
    el.addEventListener("click", this.click.bind(this));
  }

  animate() {
    this.update();
    this.draw();

    this.raf = requestAnimationFrame(this.handlerAnimate);
  }

  // ---------------------------------------------------------------------------------------------
  // PUBLIC
  // ---------------------------------------------------------------------------------------------

  update() {
    if (this.gui.stats) this.gui.stats.begin();
    if (this.webgl) this.webgl.update();
    if (this.gui) this.gui.update();
  }

  draw() {
    if (this.webgl) this.webgl.draw();
    if (this.gui.stats) this.gui.stats.end();
  }

  // ---------------------------------------------------------------------------------------------
  // EVENT HANDLERS
  // ---------------------------------------------------------------------------------------------

  resize() {
    if (this.webgl) this.webgl.resize();
  }

  keyup(e) {
    // g
    if (e.keyCode == 71) {
      if (this.gui) this.gui.toggle();
    }
  }

  click(e) {
    this.webgl.next();
  }
}
